import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './campaign.reducer';
import { CampaignEffects } from './campaign.effects';

export const FEATURE_NAME = 'campaign';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([CampaignEffects])],
    providers: [CampaignEffects],
})
export class CampaignStoreModule {}
