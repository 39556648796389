import {
    EditBookingB2c,
    IBookingOrderInstallment,
    IBookingOrderItem,
    IEditBookingB2c,
} from '../../models/edit-booking-b2c.model';
import { IOrderDetail } from '../../../../models/order.model';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter, map, take } from 'rxjs/operators';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { selectEditExistingBookingId } from 'src/app/stores/order-store/order.selectors';
import * as UserActions from 'src/app/stores/user-store/user.actions';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { State } from 'src/app/stores/reducers';
import { selectAgencyInfo, selectUsersList } from 'src/app/stores/user-store/user.selectors';
import { COMMUNICATION_METHODS } from 'src/app/constants/communication-method.constant';
import { Agency } from 'src/app/models/agency';
import { DateHelper } from 'src/app/helpers/date.helper';
import * as _ from 'lodash';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-edit-booking-b2c',
    templateUrl: './edit-booking-b2c.component.html',
    styleUrls: ['./edit-booking-b2c.component.scss'],
})
export class EditBookingB2cComponent implements OnInit {
    private id: number;
    orderDetail: IOrderDetail;
    editBookingB2c: IEditBookingB2c;
    modalTitle: string = 'สร้าง Booking';
    isCustomProductSelected: boolean = false;
    showFormErrorMessage: boolean = false;
    validFields: any;
    validFieldsOrderItems: { description: boolean; price: boolean; quantity: boolean; total: boolean };
    validFieldsOrderInstallments: { paymentDuedateAt: boolean; totalPaid: boolean };

    fieldStartDate: Date;
    fieldEndDate: Date;

    salesDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    salesDropdownItems: SearchableDropdownItem[] = [];
    partnersDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    partnersDropdownItems: SearchableDropdownItem[] = [];
    productsDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    productsDropdownItems: SearchableDropdownItem[] = [];
    communicationDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    communicationDropdownItems: SearchableDropdownItem[] = [];

    private isSubmitted: boolean = false;
    private isWarningModalEnabled: boolean = true;
    private completed: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.editBookingB2c) {
            this.editBookingB2c = EditBookingB2c.initNewBooking();
        }
        this.retrieveValues();
        this.setSalesDropdown();
        this.setPartnersDropdown();
        this.setProductsDropdown();
        this.setCommunicationMethodsDropdown();
        this.bsModalService.setDismissReason(this.id + '-booking-b2c-cancel');
        this.initValidFields();

        this.closeOnRouteChange();
    }

    private retrieveValues(): void {
        this.editBookingB2c.seller_agency_member_id = this.orderDetail.sellerId;
        this.editBookingB2c.contact = {
            contact_name: this.orderDetail.contactName,
            phone_number: this.orderDetail.phoneNumber,
            email: this.orderDetail.emailAddress,
            lead_type_slug: this.orderDetail.leadTypeSlug,
            customer_remark: this.orderDetail.customerRemark,
        };
        this.editBookingB2c.product = {
            pool_key: this.orderDetail.poolKey,
            owner_id: this.orderDetail.ownerId,
            owner_channel: this.orderDetail.ownerChannel,
            custom_product_name: this.orderDetail.customProductName,
            start_at: this.orderDetail.startDate,
            end_at: this.orderDetail.endDate,
            commission_company: this.orderDetail.commissionCompany || null,
            commission_seller: this.orderDetail.commissionSeller || null,
        };
        this.editBookingB2c.order_items = this.orderDetail.orderItems.map((item) => {
            return {
                id: item.id,
                description: item.description,
                price: item.price,
                quantity: item.quantity,
                total: item.total,
                to_update: false,
            };
        });
        this.editBookingB2c.order_installments = this.orderDetail.orderInstallments.map((installment) => {
            return {
                id: installment.id,
                payment_duedate_at: installment.paymentDueDate,
                total_paid: installment.totalPaid,
                to_update: false,
            };
        });
        if (this.editBookingB2c.product.custom_product_name) {
            this.isCustomProductSelected = true;
        }
        if (this.editBookingB2c.product.start_at) {
            this.fieldStartDate = new Date(this.editBookingB2c.product.start_at);
        }
        if (this.editBookingB2c.product.end_at) {
            this.fieldEndDate = new Date(this.editBookingB2c.product.end_at);
        }
    }

    private setSalesDropdown(): void {
        this.store.dispatch(UserActions.getUsersList(true));
        this.store
            .pipe(
                select(selectUsersList),
                filter((resp) => !!resp),
                take(1),
                map((usersList) => {
                    return usersList.map((user) => {
                        const dropDownItem = new SearchableDropdownItem({
                            id: user.id,
                            name: user.nameString,
                            isChecked: user.id === this.editBookingB2c.seller_agency_member_id,
                        });
                        this.salesDropdownItems.push(dropDownItem);
                    });
                })
            )
            .subscribe();

        this.salesDropdownSettings.dropdownListPlaceholder = 'เลือกเซลล์บริษัท (ผู้ขาย)';
        this.salesDropdownSettings.hideSearchBox = true;
        this.salesDropdownSettings.multipleSelection = false;
        this.salesDropdownSettings.closeAfterSelect = true;
        this.salesDropdownSettings.unableToUncheck = true;
        this.salesDropdownSettings.styleSetting.fontSize = '12px';
        this.salesDropdownSettings.styleSetting.height = '30px';
    }

    private setPartnersDropdown(): void {
        this.store.select(selectAgencyInfo).subscribe((agency: Agency) => {
            if (
                !this.orderDetail.ownerId ||
                (agency.id === this.editBookingB2c.product.owner_id && this.orderDetail.ownerChannel === 'ag')
            ) {
                const dropDownItem = new SearchableDropdownItem({
                    id: this.editBookingB2c.product.owner_id,
                    name: 'Owner (' + agency.nameEN + ' (' + agency.nameTH + '))',
                    isChecked: true,
                });
                this.partnersDropdownItems.push(dropDownItem);
            } else {
                const dropDownItem = new SearchableDropdownItem({
                    id: this.editBookingB2c.product.owner_id,
                    name: this.orderDetail.ownerNameEn + ' (' + this.orderDetail.ownerNameTh + ')',
                    isChecked: true,
                });
                this.partnersDropdownItems.push(dropDownItem);
            }
        });

        this.partnersDropdownSettings.dropdownListPlaceholder = 'ค้นหาเจ้าของโปรแกรม';
        this.partnersDropdownSettings.searchBoxIgnoreCase = true;
        this.partnersDropdownSettings.searchBoxPlaceholder = 'ค้นหาเจ้าของโปรแกรม';
        this.partnersDropdownSettings.customSearchIcon = '';
        this.partnersDropdownSettings.multipleSelection = false;
        this.partnersDropdownSettings.closeAfterSelect = true;
        this.partnersDropdownSettings.unableToUncheck = true;
        this.partnersDropdownSettings.styleSetting.fontSize = '12px';
        this.partnersDropdownSettings.styleSetting.height = '30px';
        this.partnersDropdownSettings.underlineAfterFirstItem = true;
        this.partnersDropdownSettings.isXScrollable = true;
    }

    private setProductsDropdown(): void {
        if (this.isCustomProductSelected) {
            const dropDownItem = new SearchableDropdownItem({
                id: null,
                name: 'สร้าง Booking (จากสินค้านอกระบบ)',
                isChecked: true,
            });
            this.productsDropdownItems.push(dropDownItem);
        } else {
            const dropDownItem = new SearchableDropdownItem({
                id: null,
                name: this.orderDetail.productName,
                isChecked: true,
            });
            this.productsDropdownItems.push(dropDownItem);
        }

        this.productsDropdownSettings.dropdownListPlaceholder = 'เลือกโปรแกรมทัวร์';
        this.productsDropdownSettings.hideSearchBox = false;
        this.productsDropdownSettings.searchBoxIgnoreCase = true;
        this.productsDropdownSettings.searchBoxPlaceholder = 'ค้นหาโปรแกรมทัวร์';
        this.productsDropdownSettings.customSearchIcon = '';
        this.productsDropdownSettings.multipleSelection = false;
        this.productsDropdownSettings.closeAfterSelect = true;
        this.productsDropdownSettings.unableToUncheck = true;
        this.productsDropdownSettings.styleSetting.fontSize = '12px';
        this.productsDropdownSettings.styleSetting.height = '30px';
        this.productsDropdownSettings.isXScrollable = true;
    }

    private setCommunicationMethodsDropdown(): void {
        this.communicationDropdownItems = [];
        COMMUNICATION_METHODS.map((option) => {
            if (option.id !== '') {
                option.id = option.id.toLowerCase();
                this.communicationDropdownItems.push(
                    new SearchableDropdownItem({
                        id: option.id,
                        name: option.text,
                        isChecked: option.id === this.editBookingB2c.contact.lead_type_slug,
                    })
                );
            }
        });

        this.communicationDropdownSettings.dropdownListPlaceholder = 'ช่องทางการติดต่อ';
        this.communicationDropdownSettings.hideSearchBox = true;
        this.communicationDropdownSettings.multipleSelection = false;
        this.communicationDropdownSettings.closeAfterSelect = true;
        this.communicationDropdownSettings.unableToUncheck = true;
        this.communicationDropdownSettings.styleSetting.fontSize = '12px';
        this.communicationDropdownSettings.styleSetting.height = '30px';
    }

    onSelectedSales(salesId: number[]): void {
        this.editBookingB2c.seller_agency_member_id = salesId[0];
    }

    setStartAt(date: Date) {
        if (date) {
            this.fieldStartDate = date;
            this.editBookingB2c.product.start_at = DateHelper.getYMDString(date);
        }
        this.validate();
    }

    setEndAt(date: Date) {
        if (date) {
            this.fieldEndDate = date;
            this.editBookingB2c.product.end_at = DateHelper.getYMDString(date);
        }
        this.validate();
    }

    onSelectCommunicationMethod(communicationMethod: string[]): void {
        if (communicationMethod.length) {
            this.editBookingB2c.contact.lead_type_slug = communicationMethod[0];
        } else {
            this.editBookingB2c.contact.lead_type_slug = null;
        }
    }

    setOrderItemsFromChild(orderItems: IBookingOrderItem[]) {
        this.editBookingB2c.order_items = orderItems;
        this.validate();
    }
    setOrderInstallmentsFromChild(orderInstallments: IBookingOrderInstallment[]) {
        this.editBookingB2c.order_installments = orderInstallments;
        this.validate();
    }

    private initValidFields(): void {
        this.showFormErrorMessage = false;

        this.validFields = {
            customerName: true,
            startDate: true,
            endDate: true,
            customName: true,
            commissionCompany: true,
            commissionSeller: true,
        };

        this.validFieldsOrderItems = {
            description: true,
            price: true,
            quantity: true,
            total: true,
        };

        this.validFieldsOrderInstallments = {
            paymentDuedateAt: true,
            totalPaid: true,
        };
    }

    validate(): boolean {
        if (!this.isSubmitted) {
            return true;
        }
        let validity = true;
        this.initValidFields();

        if (!this.editBookingB2c.contact.contact_name || this.editBookingB2c.contact.contact_name.trim() === '') {
            this.validFields.customerName = false;
            validity = false;
        }
        if (!this.editBookingB2c.product.start_at) {
            this.validFields.startDate = false;
            validity = false;
        }
        if (!this.editBookingB2c.product.end_at) {
            this.validFields.endDate = false;
            validity = false;
        }
        if (this.isCustomProductSelected) {
            if (!this.editBookingB2c.product.custom_product_name) {
                this.validFields.customName = false;
                validity = false;
            }
            if (this.editBookingB2c.product.commission_company && this.editBookingB2c.product.commission_company < 1) {
                this.validFields.commissionCompany = false;
                validity = false;
            }
            if (this.editBookingB2c.product.commission_seller && this.editBookingB2c.product.commission_seller < 1) {
                this.validFields.commissionSeller = false;
                validity = false;
            }
        }

        if (!this.editBookingB2c.order_items) {
            this.validFieldsOrderItems = {
                description: false,
                price: false,
                quantity: false,
                total: false,
            };
        } else {
            if (!this.editBookingB2c.order_items[0].description) {
                this.validFieldsOrderItems.description = false;
                validity = false;
            }
            if (!this.editBookingB2c.order_items[0].price) {
                this.validFieldsOrderItems.price = false;
                validity = false;
            }
            if (!this.editBookingB2c.order_items[0].quantity) {
                this.validFieldsOrderItems.quantity = false;
                validity = false;
            }
            if (!this.editBookingB2c.order_items[0].total) {
                this.validFieldsOrderItems.total = false;
                validity = false;
            }
        }

        if (!this.editBookingB2c.order_installments) {
            this.validFieldsOrderInstallments = {
                paymentDuedateAt: false,
                totalPaid: false,
            };
        } else {
            if (!this.editBookingB2c.order_installments[0].payment_duedate_at) {
                this.validFieldsOrderInstallments.paymentDuedateAt = false;
                validity = false;
            }
            if (!this.editBookingB2c.order_installments[0].total_paid) {
                this.validFieldsOrderInstallments.totalPaid = false;
                validity = false;
            }
        }

        this.showFormErrorMessage = !validity;
        return validity;
    }

    submit(): void {
        this.isSubmitted = true;
        if (this.validate()) {
            this.store.dispatch(
                OrderActions.editExistingBooking({
                    id: this.id,
                    postBody: _.cloneDeep(this.editBookingB2c),
                })
            );

            this.store
                .pipe(
                    select(selectEditExistingBookingId),
                    filter((resp) => !!resp)
                )
                .subscribe(() => {
                    this.store.dispatch(OrderActions.editExistingBookingSuccess({ editedOrderId: null }));
                    this.completed = true;
                    this.bsModalService.setDismissReason('submit-booking-b2c');
                    this.bsModalRef.hide();
                });
        }
    }

    disableWarningModal(): void {
        this.isWarningModalEnabled = false;
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.disableWarningModal();
            this.bsModalRef.hide();
        });
    }
}
