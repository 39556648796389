import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { first, take, tap } from 'rxjs/operators';
import { TW_ORDER_STATUS, TW_ORDER_STATUS_OPTION } from 'src/app/constants/tw-order-status.constant';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import { TourwowOrderService } from 'src/app/services/tourwow-order.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { CustomerInfoModalComponent } from 'src/app/shared/modals/customer-info-modal/customer-info-modal.component';
import { DropdownModalComponent } from 'src/app/shared/modals/dropdown-modal/dropdown-modal.component';
import { ProductOwnerModalComponent } from 'src/app/shared/modals/product-owner-modal/product-owner-modal.component';
import { TextAreaModalComponent } from 'src/app/shared/modals/text-area-modal/text-area-modal.component';
import { CancelTwBookingComponent } from '../../modals/cancel-tw-booking/cancel-tw-booking.component';
import { Customer } from '../../models/tw-booking-model/customer.model';
import { Supplier } from '../../models/tw-booking-model/supplier.model';

import * as _ from 'lodash';
import { TW_ORDER_FILE_TYPE_OPTION } from 'src/app/constants/tw-order-file-type.constant';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { CustomerOrderInstallment } from '../../models/tw-booking-model/customer-order-installment';
import { InstallmentFile } from '../../models/tw-booking-model/Installment-file.model';
import { OrderFile } from '../../models/tw-booking-model/order-file.model';
import { TwBooking } from '../../models/tw-booking-model/tw-booking.model';
import { WorkListModalComponent } from '../../modals/work-list-modal/work-list-modal.component';
import { Store } from '@ngrx/store';
import { selectUser } from 'src/app/stores/user-store/user.selectors';
import { User } from 'src/app/models/user';
import { State } from 'src/app/stores/reducers';
import { PdfPreviewModalComponent } from '../../modals/pdf-preview-modal/pdf-preview-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManualPaymentModalComponent } from '../../modals/manual-payment-modal/manual-payment-modal.component';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
    selector: 'app-tw-booking-list-result',
    templateUrl: './tw-booking-list-result.component.html',
    styleUrls: ['./tw-booking-list-result.component.scss'],
})
export class TwBookingListResultComponent implements OnInit {
    @Input() twBooking: PaginationResponse<TwBooking>;
    @Output() changePinEmitter: EventEmitter<TwBooking> = new EventEmitter();
    @Output() changeOrderStatusEmitter: EventEmitter<TwBooking> = new EventEmitter();
    @Output() changeNoteEmitter: EventEmitter<TwBooking> = new EventEmitter();
    @Output() reload = new EventEmitter();
    bsModalRef: BsModalRef;

    private user: User;

    constructor(
        private store: Store<State>,
        private modalService: BsModalService,
        private tourwowOrderService: TourwowOrderService,
        private ngxService: NgxUiLoaderService,
        private paymentService: PaymentService
    ) {}

    ngOnInit(): void {
        this.store.select(selectUser).subscribe((user) => (this.user = user));
    }

    openSupplierInfoModal(supplier: Supplier) {
        const initialState = {
            productOwner: {
                name: supplier.nameEn + ' (' + supplier.nameTh + ')',
                email: supplier.email,
                address: supplier.addresses,
                licenseNumber: supplier.tourismLicense,
                telephoneNumber: supplier.phoneNumber,
                additionTelephoneNumber: supplier.additionPhoneNumber,
            },
            usingLatestProductOwner: false,
            ownerChannel: 'ws',
            ownerId: supplier.id,
        };

        this.modalService.show(ProductOwnerModalComponent, {
            initialState,
            class: 'product-owner-modal modal-lg',
        });
    }

    openCustomerInfoModal(customer: Customer) {
        this.modalService.show(CustomerInfoModalComponent, {
            initialState: {
                customerInfo: customer,
            },
            class: 'customer-info modal-lg',
        });
    }

    onCancelOrder(order: TwBooking): void {
        const initialState = {
            booking: order,
        };
        this.bsModalRef = this.modalService.show(CancelTwBookingComponent, {
            initialState,
            class: 'cancel-tw-booking cancel-modal',
        });

        this.bsModalRef.content.canceledBookingResult.pipe(take(1)).subscribe((res) => {
            this.twBooking.result[this.twBooking.result.findIndex((booking) => booking.orderCode === res.orderCode)] =
                res;
        });
    }

    goToOnlineBooking(onlineBookingUrl: string) {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(onlineBookingUrl)) {
            url += 'http://';
        }
        url += onlineBookingUrl;
        window.open(url, '_blank');
    }

    onChangeOrderStatus(order: TwBooking): void {
        const initialState: ModalOptions = {
            initialState: {
                title: 'สถานะ Order',
                dropdownList: TW_ORDER_STATUS_OPTION,
                dataSelected: { value: order.orderStatus, label: TW_ORDER_STATUS[order.orderStatus] },
            },
        };
        this.bsModalRef = this.modalService.show(DropdownModalComponent, initialState);

        this.bsModalRef.content.dropdownModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                this.openSuccessModal();
                order.orderStatus = resp.data.value;
                this.changeOrderStatusEmitter.emit(order);
            }
        });
    }

    openNoteModal(order: TwBooking): void {
        const initialState: ModalOptions = {
            initialState: {
                title: 'Note (สำหรับภายใน)',
                initData: order.note,
            },
        };
        this.bsModalRef = this.modalService.show(TextAreaModalComponent, initialState);

        this.bsModalRef.content.textAreaModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                this.openSuccessModal();
                order.note = resp.data;
                this.changeNoteEmitter.emit(order);
            }
        });
    }

    onChangePin(order: TwBooking, isPinned: boolean): void {
        let initModalState = {};
        if (isPinned) {
            initModalState = {
                title: 'คุณต้องการปักหมุด Order นี้หรือไม่',
                detail: 'Order ที่ปักหมุดจะแสดงอยู่ส่วนบนสุดเสมอ',
            };
        } else {
            initModalState = {
                title: 'คุณต้องการยกเลิกการปักหมุด Order นี้หรือไม่',
            };
        }

        const initialState: ModalOptions = {
            initialState: initModalState,
        };

        this.bsModalRef = this.modalService.show(ConfirmModalComponent, initialState);

        this.bsModalRef.content.confirmModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                order.isPinned = isPinned;
                this.changePinEmitter.emit(order);
            }
        });
    }

    downloadFile(file): void {
        FileSaver.saveAs(file.fileUrl, file.itemFileName);
    }

    onUploadInvoiceFiles(event: Event, order: TwBooking): void {
        const HTMLInputElementEvent = event.target as HTMLInputElement;
        if (HTMLInputElementEvent.files?.length) {
            const fileListArray: File[] = Array.from(HTMLInputElementEvent.files);
            const formData = new FormData();

            const initialState: ModalOptions = {
                initialState: {
                    title: 'แนบ Invoice จาก WS',
                    dropdownList: TW_ORDER_FILE_TYPE_OPTION,
                    dataSelected: { value: null, label: 'กรุณาเลือกประเภทไฟล์' },
                },
            };
            this.bsModalRef = this.modalService.show(DropdownModalComponent, initialState);

            this.bsModalRef.content.dropdownModalResponse.pipe(first()).subscribe((resp) => {
                if (resp.status) {
                    for (let i = 0; i < fileListArray.length; i++) {
                        formData.append('file', fileListArray[i], fileListArray[i].name);
                        formData.append('file_type_slug', resp.data.value);

                        this.tourwowOrderService
                            .uploadTwBookingInvoiceFile(order.orderCode, formData)
                            .pipe(first())
                            .subscribe((resp) => {
                                if (resp) {
                                    if (i === fileListArray.length - 1) {
                                        this.openSuccessModal();
                                    }
                                    order.orderFiles.push(new OrderFile().deserialize(resp.data.order_file));
                                }
                            });
                    }
                } else {
                    HTMLInputElementEvent.value = null;
                }
            });
        }
    }

    onUploadCustomerInstallmentFiles(
        event: Event,
        order: TwBooking,
        customerOrderInstallment: CustomerOrderInstallment
    ): void {
        const HTMLInputElementEvent = event.target as HTMLInputElement;
        let fileListArray: File[] = [];
        if (HTMLInputElementEvent.files?.length) {
            fileListArray = Array.from(HTMLInputElementEvent.files);

            const formData = new FormData();

            const initialState: ModalOptions = {
                initialState: {
                    title: 'แนบ Invoice จาก WS',
                    dropdownList: TW_ORDER_FILE_TYPE_OPTION,
                    dataSelected: { value: null, label: 'กรุณาเลือกประเภทไฟล์' },
                },
            };
            this.bsModalRef = this.modalService.show(DropdownModalComponent, initialState);

            this.bsModalRef.content.dropdownModalResponse.pipe(first()).subscribe((resp) => {
                if (resp.status && resp.data && resp.data.value) {
                    for (let i = 0; i < fileListArray.length; i++) {
                        formData.append('file', fileListArray[i], fileListArray[i].name);
                        formData.append('file_type_slug', resp.data.value);

                        this.tourwowOrderService
                            .uploadTwBookingCustomerInstallmentFile(
                                order.orderCode,
                                customerOrderInstallment.id,
                                formData
                            )
                            .pipe(first())
                            .subscribe((resp) => {
                                if (resp) {
                                    if (i === fileListArray.length - 1) {
                                        this.openSuccessModal();
                                    }
                                    customerOrderInstallment.filesForDisplay.push(
                                        new InstallmentFile().deserialize(
                                            resp.data.customer_order_installment_file,
                                            customerOrderInstallment.id
                                        )
                                    );
                                }
                            });
                    }
                }
            });
        }
    }

    onDeleteInvoiceFile(file: OrderFile, order: TwBooking): void {
        const initialState: ModalOptions = {
            initialState: {
                title: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้',
            },
        };

        this.bsModalRef = this.modalService.show(ConfirmModalComponent, initialState);

        this.bsModalRef.content.confirmModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                this.tourwowOrderService
                    .deleteTwBookingInvoiceFile(order.orderCode, file.id)
                    .pipe(first())
                    .subscribe((resp) => {
                        _.remove(order.orderFiles, (orderFile) => orderFile == file);
                    });
            }
        });
    }

    onDeleteInstallmentFile(order: TwBooking, installment: CustomerOrderInstallment, file: InstallmentFile): void {
        const initialState: ModalOptions = {
            initialState: {
                title: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้',
            },
        };

        this.bsModalRef = this.modalService.show(ConfirmModalComponent, initialState);

        this.bsModalRef.content.confirmModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                if (file.isCustomerInstallmentFile) {
                    this.tourwowOrderService
                        .deleteTwBookingCustomerInstallmentFile(order.orderCode, file.installmentId, file.id)
                        .pipe(
                            tap(() =>
                                _.remove(installment.filesForDisplay, (installmentFile) => installmentFile == file)
                            ),
                            first()
                        )
                        .subscribe();
                } else {
                    this.tourwowOrderService
                        .deleteTwBookingSupplierInstallmentFile(order.orderCode, file.installmentId, file.id)
                        .pipe(
                            tap(() =>
                                _.remove(installment.filesForDisplay, (installmentFile) => installmentFile == file)
                            ),
                            first()
                        )
                        .subscribe();
                }
            }
        });
    }

    openSuccessModal(): void {
        const initialState: ModalOptions = {
            initialState: {
                message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
            },
        };

        this.bsModalRef = this.modalService.show(SuccessModalComponent, initialState);
    }

    openManualPaymentModal(mode: 'form' | 'view', booking: TwBooking, installment: CustomerOrderInstallment): void {
        const initialState = {
            mode: mode,
            booking: booking,
            installment: installment,
        };

        this.bsModalRef = this.modalService.show(ManualPaymentModalComponent, {
            initialState,
            class: 'manual-payment-modal modal-lg',
        });

        this.bsModalRef.content.isRequestPayloadNotificationComplete.subscribe((data) => {
            this.openSuccessModal();
            this.reload.next();
        });

        this.bsModalRef.content.isRequestCancelPaymentRequest.subscribe((data) => {
            const cancelInstallmentId = data;
            this.popupConfirmCancelPaymentRequest(cancelInstallmentId, installment);
        });
    }

    openWorkListModal(booking: TwBooking): void {
        const initialState = {
            orderCode: booking.orderCode,
            userRole: this.user.roleSlug,
        };

        this.bsModalRef = this.modalService.show(WorkListModalComponent, {
            initialState,
            class: 'work-list-modal modal-lg',
        });

        this.bsModalRef.content.alreadySave.pipe(take(1)).subscribe((orderCode) => {
            this.twBooking.result[
                this.twBooking.result.findIndex((booking) => booking.orderCode === orderCode)
            ].hasTask = true;
            this.openSuccessModal();
        });
    }

    openPdfPreviewModal(orderCode: string, tourCode: string) {
        this.ngxService.start();
        this.tourwowOrderService
            .getTwOrderReceiptPdf(orderCode)
            .pipe(first())
            .subscribe((resp) => {
                this.ngxService.stop();
                const initialState: ModalOptions = {
                    initialState: {
                        pdfBlob: resp,
                        pdfName: tourCode,
                    },
                    class: 'pdf-preview-modal modal-lg',
                };

                this.bsModalRef = this.modalService.show(PdfPreviewModalComponent, initialState);
            });
    }

    private popupConfirmCancelPaymentRequest(cancelInstallmentId: number, installment: CustomerOrderInstallment): void {
        const initialState: ModalOptions = {
            initialState: {
                title: 'คุณต้องการยกเลิกการชำระเงินหรือไม่',
            },
        };

        this.bsModalRef = this.modalService.show(ConfirmModalComponent, initialState);
        this.bsModalRef.content.confirmModalResponse.pipe(first()).subscribe((resp) => {
            if (resp.status) {
                this.paymentService.cancelPaymentRequest(cancelInstallmentId).subscribe((response) => {
                    this.openSuccessModal();
                    this.reload.next();
                });
            }
        });
    }
}
