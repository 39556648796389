import { ThaiDatePipe, ThaiDateRangePipe, ThaiMonthDatePipe } from '../../../../pipes/thaidate.pipe';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectSaleReportListPaginated, selectSaleReportListQueries } from 'src/app/stores/order-store/order.selectors';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ISaleReportListPaginatedQueries,
    SaleReportItem,
    SaleReportListPaginated,
} from '../../models/sale-report.model';
import { SearchResultLabelHelper } from '../../helpers/search-result-label.helper';
import * as _ from 'lodash';
import { Agency } from 'src/app/models/agency';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';

@Component({
    selector: 'app-sale-report-listing',
    templateUrl: './sale-report-listing.component.html',
    styleUrls: ['./sale-report-listing.component.scss'],
})
export class SaleReportListingComponent implements OnInit, OnDestroy {
    saleReportListQuery: ISaleReportListPaginatedQueries;
    saleReportListPaginated: SaleReportListPaginated;
    filterText: string;

    @Output() clickViewMoreDetail = new EventEmitter<SaleReportItem>();

    private agency: Agency;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private thaiDatePipe: ThaiDatePipe,
        private thaiDateRangePipe: ThaiDateRangePipe,
        private thaiMonthDatePipe: ThaiMonthDatePipe
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agency) => !!agency),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agency) => (this.agency = agency));

        this.store
            .pipe(
                select(selectSaleReportListPaginated),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response) => {
                response = _.cloneDeep(response);
                let itemsToCopy = [];
                if (response.currentPage > 1) {
                    itemsToCopy = this.saleReportListPaginated.items.concat(response.items);
                }
                if (itemsToCopy.length) {
                    response.items = itemsToCopy;
                }

                this.saleReportListPaginated = response;
            });

        this.store
            .pipe(
                select(selectSaleReportListQueries),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response) => {
                this.saleReportListQuery = response;
                this.filterText = this.thaiMonthDatePipe.transform(
                    this.saleReportListQuery.filters.created_at_between.min_date,
                    false
                );
            });
    }

    doAdvanceFilter(report: SaleReportItem): void {
        this.clickViewMoreDetail.emit(report);
    }

    loadMoreResults(): void {
        const query = _.cloneDeep(this.saleReportListQuery);
        query.criteria.page++;
        this.store.dispatch(
            OrderActions.getSaleReportListPaginated({
                query: query,
            })
        );
    }

    getDynamicTotalString(): string {
        const productOwner = {
            nameEn: this.saleReportListPaginated?.items[0]?.productOwner.nameEN,
            nameTh: this.saleReportListPaginated?.items[0]?.productOwner.nameTH,
        };
        const sellerDetails = {
            firstName: this.saleReportListPaginated?.items[0]?.sellerFirstName,
            lastName: this.saleReportListPaginated?.items[0]?.sellerLastName,
            nickName: this.saleReportListPaginated?.items[0]?.sellerNickName,
        };

        return SearchResultLabelHelper.getDynamicTotalString(
            this.saleReportListQuery,
            this.thaiDatePipe,
            this.thaiDateRangePipe,
            productOwner,
            sellerDetails,
            this.agency?.productCode
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
