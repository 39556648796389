import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SanitizerUrlPipe } from 'src/app/pipes/sanitize-url.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import * as _ from 'lodash';
import { CustomerOrderInstallment } from 'src/app/modules/order/models/tw-booking-model/customer-order-installment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaymentFilterPipeModule } from '../../pipes/payment-filter-pipe.module';
import { IPaymentEvent } from 'src/app/modules/order/models/interfaces/tw-booking-interface/payment-event.interface';
import { PaymentMethodPipeModule } from 'src/app/directives/texts/payment-method-pipe.module';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-form-manual-payment',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        TimepickerModule,
        ReactiveFormsModule,
        SanitizerUrlPipe,
        FontAwesomeModule,
        PaymentFilterPipeModule,
        PaymentMethodPipeModule,
    ],
    templateUrl: './form-manual-payment.component.html',
    styleUrls: ['./form-manual-payment.component.scss'],
})
export class FormManualPaymentComponent {
    @ViewChild('fileInput') fileInput?: ElementRef;
    @Input() installment!: CustomerOrderInstallment;
    @Input() isSubmit: boolean = false;

    faTrash = faTrashCan;
    mytime: Date = new Date();
    paymentForm = this.fb.group({
        method: ['bank_transfer', Validators.required],
        amount: ['', [Validators.required]],
        paid_at: [new Date(), Validators.required],
        installments: [[]],
        note: [''],
    });

    constructor(@Inject(PLATFORM_ID) protected platformId: Object, private fb: FormBuilder, private http: HttpClient) {}

    get f(): { [key: string]: FormControl } {
        return this.paymentForm.controls;
    }

    insertImage(event: Event) {
        const HTMLInputElementEvent = event.target as HTMLInputElement;
        let fileListArray: File[] = [];

        if (HTMLInputElementEvent.files?.length) {
            fileListArray = Array.from(HTMLInputElementEvent.files);
            fileListArray.forEach(async (file) => {
                let response = await this.uploadImg(file);
                this.f['installments'].value.push({
                    url: URL.createObjectURL(file),
                    file: file,
                    id: response.data.file_id,
                });
            });
        }
    }

    deleteImage(img: IImageForm) {
        this.fileInput.nativeElement.value = '';
        _.remove(this.f['installments'].value, (file) => file == img);
    }

    uploadImg(file: File) {
        let url = `${environment.apiProUrl}payments/tw/file`;
        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.http
            .post<any>(url, formData, {
                reportProgress: true,
                observe: 'events',
                headers: {
                    Authorization: this.getToken(),
                },
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round((100 * event.loaded) / event.total);
                            return { status: 'progress', message: progress };

                        case HttpEventType.Response:
                            return event.body;
                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                })
            )
            .toPromise();
    }

    getTwPaymentEventById(twPaymentEventId: number): IPaymentEvent | null {
        if (this.installment && this.installment.twPaymentEvents) {
            return this.installment.twPaymentEvents.find((f) => f.id === twPaymentEventId);
        }
        return null;
    }

    private getToken(): string {
        let token: string;
        if (isPlatformBrowser(this.platformId)) {
            token = localStorage.getItem('token');
        }

        return token;
    }
}

interface IImageForm {
    url: string;
    file: File;
    id: number;
}
