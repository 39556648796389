import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
    transform(tel: string): string {
        let first: string, second: string, third: string;

        if (tel) {
            switch (tel.length) {
                case 9:
                    first = tel.slice(0, 2);
                    second = tel.slice(2, 5);
                    third = tel.slice(5);
                    return first + '-' + second + '-' + third;
                case 10:
                    first = tel.slice(0, 3);
                    second = tel.slice(3, 6);
                    third = tel.slice(6);
                    return first + '-' + second + '-' + third;
                default:
                    return tel;
            }
        }
        return null;
    }
}
