import { ICommissionReport } from '../../models/commission-report-list.model';
import { AddBookingB2cComponent } from '../../modals/add-booking-b2c/add-booking-b2c.component';
import { CloseAdvancedSearchWarningComponent } from './../../modals/close-advanced-search-warning/close-advanced-search-warning.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { BOOKING_LIST_TABS } from 'src/app/constants/booking-list-tabs.contstant';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { State } from 'src/app/stores/reducers';
import { Subject } from 'rxjs';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { IBookingAdvanceSearch } from '../../models/booking-advance-search.model';
import {
    IBookingListQueries,
    IBookingListOrderStatusFilter,
    BOOKING_LIST_SORT_BY,
} from '../../models/booking-list.model';
import { ISaleReportListPaginatedQueries, SaleReportItem } from '../../models/sale-report.model';
import { filter, take } from 'rxjs/operators';
import { ICommissionReportListQueries } from '../../models/commission-report-list-queries';
import { IGroupPlanListQueries } from 'src/app/modules/order/models/group-plan-list-queries';
import { IGroupPlan } from '../../models/group-plan-list.model';
import * as _ from 'lodash';
import { DateHelper } from 'src/app/helpers/date.helper';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-booking-list',
    templateUrl: './booking-list.component.html',
    styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent implements OnInit, OnDestroy {
    readonly BOOKING_LIST_TABS = BOOKING_LIST_TABS;

    breadcrumb: IBreadcrumb[];
    showAdvanceFilter: boolean = false;
    activeTab: number = BOOKING_LIST_TABS.BOOKING_LIST;
    private bsModalRef: BsModalRef;
    private unsubscribe$: Subject<void> = new Subject<void>();

    advancedSearchPresets: IAdvancedSearchPresets;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.setBreadcrumb();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'สถานะการจอง', link: '/order/booking-list' },
        ];
    }

    openModalForCreatingBooking(): void {
        this.bsModalRef = this.modalService.show(AddBookingB2cComponent, {
            class: 'add-booking-b2c-modal',
        });
    }

    toggleAdvanceFilter(specificInstruction: boolean = null): void {
        if (specificInstruction !== null) {
            this.showAdvanceFilter = specificInstruction;
        } else {
            if (this.showAdvanceFilter) {
                this.bsModalRef = this.modalService.show(CloseAdvancedSearchWarningComponent, {
                    class: 'close-advanced-search-modal',
                });

                this.modalService.onHide
                    .pipe(
                        filter((reason) => reason === 'confirm-close-advanced'),
                        take(1)
                    )
                    .subscribe(() => {
                        this.showAdvanceFilter = false;
                    });
            } else {
                this.showAdvanceFilter = true;
            }
        }
    }

    changeActiveTab(activeTab: BOOKING_LIST_TABS): void {
        if (activeTab !== BOOKING_LIST_TABS.BOOKING_LIST) {
            this.advancedSearchPresets = null;
        }
        this.activeTab = activeTab;
        this.toggleAdvanceFilter(false);
    }

    doAdvanceSearch(model: IBookingAdvanceSearch): void {
        const query = this.buildQueryFromAdvancedSearch(model);
        switch (this.activeTab) {
            case BOOKING_LIST_TABS.BOOKING_LIST:
                this.dispatchBookingList(query);
                break;
            case BOOKING_LIST_TABS.SALES_REVENUE:
                this.dispatchSaleReportList(query);
                break;
            case BOOKING_LIST_TABS.GROUP_PLAN:
                this.dispatchGroupPlan(query);
                break;
            case BOOKING_LIST_TABS.COMMISSION_REPORT:
                this.dispatchCommissionList(query);
                break;
            default:
                break;
        }
    }

    focusedBookingListSearchFromSalesReportClick(model: SaleReportItem): void {
        const bookingListQuery: IBookingListQueries = {
            criteria: {
                page: 1,
                pageEntries: 50,
            },
            filters: {
                clearCache: new Date().toISOString(),
                order_ids: [model.id],
            },
            sortBy: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
        };

        this.dispatchBookingList(bookingListQuery);
        this.activeTab = BOOKING_LIST_TABS.BOOKING_LIST;

        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }

        this.advancedSearchPresets = {
            nameOrNumber: model.contactName,
            bookingDate: model.created,
            ownerId: model.productOwnerId,
            ownerChannel: model.productOwnerChannelType,
            poolKey: model.productId
                ? model.productOwnerChannelType.toUpperCase() + model.categorySubId + model.productId
                : null,
            customProductName: !model.productId ? model.productName : null,
            travelStartDate: model.startDate,
            salesId: model.sellerId,
        };
    }

    focusedBookingListSearchFromGroupPlanClick(report: IGroupPlan): void {
        const bookingListQuery: IBookingListQueries = {
            criteria: {
                page: 1,
                pageEntries: 50,
            },
            filters: {
                clearCache: new Date().toISOString(),
                order_ids: report.orderIds,
            },
            sortBy: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
        };
        this.dispatchBookingList(bookingListQuery);
        this.activeTab = BOOKING_LIST_TABS.BOOKING_LIST;

        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }

        this.advancedSearchPresets = {
            ownerId: report.productOwner.id,
            ownerChannel: report.productOwnerChannel,
            poolKey: report.productsId
                ? report.productOwnerChannel.toUpperCase() + report.proCategorySubProductsId + report.productsId
                : null,
            customProductName: !report.productsId ? report.productName : null,
            travelStartDate: DateHelper.getYMDString(report.startAt),
        };
    }

    focusedBookingListSearchFromCommissionClick(report: ICommissionReport): void {
        const bookingListQuery: IBookingListQueries = {
            criteria: {
                page: 1,
                pageEntries: 50,
            },
            filters: {
                clearCache: new Date().toISOString(),
                order_ids: [report.id],
            },
            sortBy: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
        };
        this.dispatchBookingList(bookingListQuery);
        this.activeTab = BOOKING_LIST_TABS.BOOKING_LIST;

        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }

        this.advancedSearchPresets = {
            nameOrNumber: report.contactName,
            bookingDate: report.createdAt,
            ownerId: report.productOwner.id,
            ownerChannel: report.productOwnerChannel,
            poolKey: report.productsId
                ? report.productOwnerChannel.toUpperCase() + report.proCategorySubProductsId + report.productsId
                : null,
            customProductName: !report.productsId ? report.productName : null,
            travelStartDate: report.startAt,
            salesId: report.sellerAgencyMember?.id,
        };
    }

    private buildQueryFromAdvancedSearch(model: IBookingAdvanceSearch): IBookingListQueries {
        const bookingListQuery: IBookingListQueries = {
            criteria: {
                page: 1,
                pageEntries: 50,
            },
            filters: {
                clearCache: new Date().toISOString(),
                order_statuses: model.bookingStatuses?.map((x) => {
                    const m: IBookingListOrderStatusFilter = {
                        status_code: x.statusCode,
                        already_started: x.isStarted,
                    };
                    return m;
                }),
                contact_name_or_phone_number: model.contactNameOrPhoneNumber,
                product_tour_code: model?.product.tourCode,
                product_name: model?.product.name,
                product_owner: {
                    id: model.productOwner?.id,
                    channel: model.productOwner?.channel,
                },
                seller_agency_member_id: model.sellerId,
                start_at_between: {
                    min_date: DateHelper.getYMDString(model.outboundDate?.startDate),
                    max_date: DateHelper.getYMDString(model.outboundDate?.endDate),
                },
                created_at_between: {
                    min_date: DateHelper.getYMDString(model.bookingDate?.startDate),
                    max_date: DateHelper.getYMDString(model.bookingDate?.endDate),
                },
            },
        };
        if (this.activeTab === BOOKING_LIST_TABS.BOOKING_LIST) {
            bookingListQuery.sortBy = BOOKING_LIST_SORT_BY.CREATED_BY_DESC;
        }
        return bookingListQuery;
    }

    private dispatchBookingList(query: IBookingListQueries): void {
        this.store.dispatch(
            OrderActions.getBookingListPaginated({
                queries: _.cloneDeep(query),
            })
        );
    }

    private dispatchSaleReportList(query: ISaleReportListPaginatedQueries): void {
        this.store.dispatch(
            OrderActions.getSaleReportListPaginated({
                query: query,
            })
        );
    }

    private dispatchGroupPlan(query: IGroupPlanListQueries): void {
        this.store.dispatch(
            OrderActions.getGroupPlanListPaginated({
                groupPlanListQueries: query,
                isNextPage: false,
            })
        );
    }

    private dispatchCommissionList(query: ICommissionReportListQueries): void {
        this.store.dispatch(
            OrderActions.getCommissionReportListPaginated({
                commissionReportListQueries: query,
                isNextPage: false,
            })
        );
    }
}

export interface IAdvancedSearchPresets {
    nameOrNumber?: string;
    bookingDate?: string;
    ownerId: number;
    ownerChannel: string;
    poolKey: string;
    customProductName: string;
    travelStartDate: string;
    salesId?: number;
}
