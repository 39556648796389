import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './product.reducer';
import { ProductEffects } from './product.effects';

export const FEATURE_NAME = 'product';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([ProductEffects])],
    providers: [ProductEffects],
})
export class ProductStoreModule {}
