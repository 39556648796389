import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { TaxService } from 'src/app/services/tax.service';

// Modals
import { WholesalePaymentDetailModalComponent } from '../../modals/wholesale-payment-detail-modal/wholesale-payment-detail-modal.component';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';

import { RoomType } from 'src/app/models/room-type.model';
import { WholesaleProductPeriod } from 'src/app/models/wholesale-products/wholesale-product-period.model';
import { WholesaleProduct } from 'src/app/models/wholesale-products/wholesale-product.model';
import { InstallmentList } from '../../models/installment.model';
import { ISkuListForm } from '../../interfaces/sku-list-form.interface';
import { IResponseGetOrder } from 'src/app/models/interfaces/tourwow-order/response-get-order.interface';
import { first } from 'rxjs/operators';

enum MODE {
    ADD = 'add',
    EDIT = 'edit',
    VIEW = 'view',
}

@Component({
    selector: 'app-sku-list-form',
    templateUrl: './sku-list-form.component.html',
    styleUrls: ['./sku-list-form.component.scss'],
})
export class SkuListFormComponent implements OnChanges {
    MODE = MODE;
    VAT_PERCENTAGE = 7;
    @Input() program: WholesaleProduct = null;
    @Input() period: WholesaleProductPeriod = null;
    @Input() order: IResponseGetOrder;
    @Input() mode: MODE;

    @Output() skuChange = new EventEmitter<ISkuListForm>();
    @Output() formValid = new EventEmitter<boolean>();

    sku = {
        adultSingleBed: 0,
        adultDoubleBed: 0,
        adultTripleBed: 0,
        childWithBed: 0,
        childNoBed: 0,
        infant: 0,
        joinLand: 0,
    };

    customerInstallmentSelector: number;
    wholesaleInstallmentSelector: number;

    customerInstallment: InstallmentList;
    wholesaleInstallment: InstallmentList;

    discountPrice: number = null;
    totalPrice: number = 0;
    requestVat: boolean = false;

    constructor(
        private modalService: BsModalService,
        private taxService: TaxService,
        private localeService: BsLocaleService,
        private bsModalService: BsModalService
    ) {
        // change language date picker
        this.localeService.use('th-be');
    }

    ngOnChanges(changes: SimpleChanges): void {
        // On period selected.
        if (changes.period && changes.period.currentValue) {
            this.resetForm();
            this.initialInstallment();

            // On Edit
            if (this.order && this.mode === MODE.VIEW) {
                this.fillOrderData(this.order);
            }
        }
    }

    get adultSingleBed(): RoomType {
        return this.getRoomType('adult_single');
    }

    get adultDoubleBed(): RoomType {
        return this.getRoomType('adult_double');
    }

    get adultTripleBed(): RoomType {
        return this.getRoomType('adult_triple');
    }

    get childWithBed(): RoomType {
        return this.getRoomType('child_bed');
    }

    get childNoBed(): RoomType {
        return this.getRoomType('child_no_bed');
    }

    get infant(): RoomType {
        return this.getRoomType('infant');
    }

    get joinLand(): RoomType {
        return this.getRoomType('join_land');
    }

    get totalRealPaid(): number {
        let realPaid = 0;
        if (this.requestVat) {
            const result = this.taxService.calulateTax(2, this.totalPrice - this.discountPrice, false);
            realPaid = result.totalRealPaid;
        } else {
            realPaid = this.totalPrice - this.discountPrice;
        }
        return realPaid;
    }

    get allSKUQuantity(): number {
        return (
            this.sku.adultSingleBed +
            this.sku.adultDoubleBed +
            this.sku.adultTripleBed +
            this.sku.childNoBed +
            this.sku.childWithBed +
            this.sku.infant +
            this.sku.joinLand
        );
    }

    private getRoomType(slug: string): RoomType {
        if (!this.period || !this.period.roomTypes) {
            return null;
        }

        const room = this.period.roomTypes.find((room) => room.slug === slug);
        if (!room) {
            return null;
        }

        if (room.price === null) {
            return null;
        }

        if (+room.price <= 0) {
            return null;
        }

        return room;
    }

    private calculateTotalPrice(): number {
        let price = 0;
        if (this.adultSingleBed) {
            price += this.adultSingleBed.price * this.sku.adultSingleBed;
        }

        if (this.adultDoubleBed) {
            price += this.adultDoubleBed.price * this.sku.adultDoubleBed;
        }

        if (this.adultTripleBed) {
            price += this.adultTripleBed.price * this.sku.adultTripleBed;
        }

        if (this.childWithBed) {
            price += this.childWithBed.price * this.sku.childWithBed;
        }

        if (this.childNoBed) {
            price += this.childNoBed.price * this.sku.childNoBed;
        }

        if (this.infant) {
            price += this.infant.price * this.sku.infant;
        }

        if (this.joinLand) {
            price += this.joinLand.price * this.sku.joinLand;
        }

        return price;
    }

    private initialInstallment(): void {
        const DAYTIME = 3600 * 1000 * 24;

        this.customerInstallment = new InstallmentList(3);
        this.customerInstallment.totalRealPaid = this.totalRealPaid;

        // new Date()
        const start: Date = this.period.startAt;
        const today: Date = new Date();
        const canPayTwoInstallment: boolean =
            new Date(start.getTime() - DAYTIME * this.program.ownerSupplier.productTwpInvoiceInstallmentSecond) >=
            today;
        if (this.period.depositIsActive && canPayTwoInstallment) {
            this.customerInstallmentSelector = 2;
            this.customerInstallment.setInstallmentAmount(2);

            this.customerInstallment.installments[0].price = this.period.deposit;
            this.customerInstallment.installments[0].date = new Date(
                Date.now() + DAYTIME * this.program.ownerSupplier.productTwpInvoiceInstallmentFirst
            );

            this.customerInstallment.installments[1].price = this.totalRealPaid - this.period.deposit;
            this.customerInstallment.installments[1].date = new Date(
                start.getTime() - DAYTIME * this.program.ownerSupplier.productTwpInvoiceInstallmentSecond
            );
        } else {
            this.customerInstallmentSelector = 1;
            this.customerInstallment.setInstallmentAmount(1);
            this.customerInstallment.installments[0].date = new Date(
                Date.now() + DAYTIME * this.program.ownerSupplier.productTwpInvoiceInstallmentFirst
            );
        }

        // On initial wholesale's installments = customer's installments settings.
        this.wholesaleInstallmentSelector = this.customerInstallmentSelector;
        this.wholesaleInstallment = _.cloneDeep(this.customerInstallment);
    }

    private fillOrderData(order: IResponseGetOrder): void {
        order.order_items.forEach((item) => {
            if (item.product_room_type.slug === 'adult_single') {
                this.sku.adultSingleBed = item.quantity;
            }
            if (item.product_room_type.slug === 'adult_double') {
                this.sku.adultDoubleBed = item.quantity;
            }
            if (item.product_room_type.slug === 'adult_triple') {
                this.sku.adultTripleBed = item.quantity;
            }
            if (item.product_room_type.slug === 'child_bed') {
                this.sku.childWithBed = item.quantity;
            }
            if (item.product_room_type.slug === 'child_no_bed') {
                this.sku.childNoBed = item.quantity;
            }
            if (item.product_room_type.slug === 'infant') {
                this.sku.infant = item.quantity;
            }
            if (item.product_room_type.slug === 'join_land') {
                this.sku.joinLand = item.quantity;
            }
        });

        this.totalPrice = order.amount;
        this.discountPrice = order.discount;
        this.requestVat = order.use_vat;

        // Customer Installment
        this.customerInstallment = new InstallmentList(3);
        this.customerInstallment.setInstallmentAmount(order.customer_order_installments.length);
        this.customerInstallmentSelector = order.customer_order_installments.length;
        order.customer_order_installments.forEach((v, i, a) => {
            this.customerInstallment.installments[i].ordinal = v.ordinal;
            this.customerInstallment.installments[i].date = new Date(v.due_date);
            this.customerInstallment.installments[i].price = v.amount;
        });

        // Wholesale Installment
        this.wholesaleInstallment = new InstallmentList(3);
        this.wholesaleInstallment.setInstallmentAmount(order.supplier_order_installments.length);
        this.wholesaleInstallmentSelector = order.supplier_order_installments.length;
        order.supplier_order_installments.forEach((v, i, a) => {
            this.wholesaleInstallment.installments[i].ordinal = v.ordinal;
            this.wholesaleInstallment.installments[i].date = new Date(v.due_date);
            this.wholesaleInstallment.installments[i].price = v.amount;
        });

        this.onChangeSkuQuantity();
    }

    private validation(): void {
        if (this.discountPrice > 0 && (this.discountPrice * 100) / this.totalPrice > 10) {
            this.formValid.emit(false);
        } else {
            this.formValid.emit(true);
        }
    }

    private resetForm(): void {
        this.sku = {
            adultSingleBed: 0,
            adultDoubleBed: 0,
            adultTripleBed: 0,
            childWithBed: 0,
            childNoBed: 0,
            infant: 0,
            joinLand: 0,
        };

        this.totalPrice = 0;
        this.discountPrice = null;
    }

    private popupModalSuccess(): void {
        this.bsModalService.show(SuccessModalComponent, {
            class: 'success-modal',
        });
    }

    // Events Listener
    popupWholesalePaymentDetail(): void {
        let deposit: number = null;
        if (this.period.depositIsActive && this.customerInstallmentSelector > 1) {
            deposit = this.period.deposit * this.allSKUQuantity;
        }

        const modal = this.modalService.show(WholesalePaymentDetailModalComponent, {
            class: 'product-owner-modal modal-lg',
            ignoreBackdropClick: true,
            initialState: {
                mode: this.mode,
                roomTypes: this.period.roomTypes,
                sku: this.sku,
                totalPrice: this.totalPrice,
                allSKUQuantity: this.allSKUQuantity,
                companyCommission: this.period.commissionCompany,
                saleCommission: this.period.commissionSeller,
                installmentSelector: this.wholesaleInstallmentSelector,
                installmentList: this.wholesaleInstallment,
                deposit: deposit,
                orderCode: this.order?.order_code,
            },
        });

        modal.content.updateInstallmentList.subscribe((installmentList: InstallmentList) => {
            this.wholesaleInstallment = installmentList;
            this.wholesaleInstallmentSelector = installmentList.getInstallmentAmount();
        });

        modal.onHide.pipe(first()).subscribe(() => {
            if (modal.content.btnAction === 'submit') {
                this.popupModalSuccess();
            }
        });
    }

    onChangeSkuQuantity(): void {
        this.validation();

        this.totalPrice = this.calculateTotalPrice();
        this.customerInstallment.totalRealPaid = this.totalRealPaid;
        this.wholesaleInstallment.totalRealPaid =
            this.totalPrice -
            this.period.commissionCompany * this.allSKUQuantity -
            this.period.commissionSeller * this.allSKUQuantity;

        if (this.mode !== MODE.VIEW && this.period.depositIsActive && this.customerInstallmentSelector > 1) {
            this.customerInstallment.resetFirstInstallmentWithDeposit(this.period.deposit * this.allSKUQuantity);
        }

        this.skuChange.emit({
            skuQuantity: this.sku,
            requestVat: this.requestVat,
            vatPercentage: this.VAT_PERCENTAGE,
            discount: this.discountPrice,
            total: this.totalPrice,
            totalIncludeVat: ((this.totalPrice - this.discountPrice) * this.VAT_PERCENTAGE) / 100,
            totalRealPaid: this.totalRealPaid,
            customerInstallment: this.customerInstallment,
            wholesaleInstallment: this.wholesaleInstallment,
        });
    }

    onChangeCustomerInstallment(): void {
        this.customerInstallment.setInstallmentAmount(this.customerInstallmentSelector);
        this.onChangeSkuQuantity();
    }

    reCalculateInstallment(): void {
        this.customerInstallment.reCalculateInstallment();
    }
}
